import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, useFormik, Field } from "formik";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isPossiblePhoneNumber }  from 'react-phone-number-input'
import * as Yup from "yup";

import bgimg from '../images/getintouch-bg.webp'
import './getintouch.css';


gsap.registerPlugin(ScrollTrigger);

const initialValues = {
    name: "",
    // company: "",
    // designation: "",
    // country: "",
    email: "",
    // phone: "",
    message: "",
}

export const Getintouch = () => {

    const [hide, setHide] = useState(false);
    const [response, setResponse] = useState();
    const [modalShow, setModalShow] = useState();

    const [phone, setphone] = useState();
    const [phoneerrmsg, setphoneerrmsg] = useState();


    const subscribeSchema = Yup.object({
        name: Yup.string().min(2).max(50).required("Please enter your name").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, "Email must be a valid email "),
        message: Yup.string().min(4).required("Please enter Message"),
     });
      

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: subscribeSchema,
            onSubmit: (values, action) => {

                if(formatPhoneNumber(phone) === "") {
                    setphoneerrmsg("Please enter your Phone Number");
                } else {

                    setphoneerrmsg();

                    const formData = new FormData();
                    formData.append("name", values.name);
                    formData.append("email", values.email);
                    formData.append("phone",  formatPhoneNumberIntl(phone));
                    formData.append("message", values.message);

                    setHide(true);
                    // https://blog.preferred-square.com/wp-json/mail/getintouch
                    // https://sandboxmachine.com/ps/wp-json/mail/getintouch
                fetch('https://blog.preferred-square.com/wp-json/mail/getintouch', {
                        method: 'POST',
                        // mode: 'no-cors',
                        // headers: {
                        // 'Content-Type': 'application/json',
                        // },
                        
                        body:formData,
                    })
                .then(res => res.json())
                .then(data => {
                // enter you logic when the fetch is successful
                  setHide(false);
                  console.log(data)
                  setResponse(data.message);
                  setModalShow(true);
                //   setTimeout(()=>{
                //     setResponse("");
                //   }, 2000);
        
                })
            
                // .catch(error => {
                //     // enter your logic for when there is an error (ex. error toast)
                //     console.log(error.message);
                // }) 
                // console.log(
                //   values
                // );
         
                  setphone();       
                action.resetForm();
               }
              
            },
        });
    // console.log(

    //     errors
    // );

    useEffect(() => {

        // gsap.to(".getintbg", {
        //     left: '0%',
        //     duration: 10, 
        //     delay: 2,
        //     scrollTrigger: {
        //         ease: "power1.out",
        //         trigger: ".getintbg",
        //     //   markers: true,
        //       start: "top bottom",
        //       end: "bottom bottom",
        //       scrub: 10
        //     }
        //   }); 

        gsap.to(".getintbg", {
            scale: 19,
            duration: 2,
            delay: 2,
            scrollTrigger: {
                ease: "power1.out",
                trigger: ".getintbg",
                //   markers: true,
                start: "top center",
                end: "top top",
                scrub: 5
            }
        });

    }, [])


    return (
        <>
            <section className='getint-section desktop' style={{ backgroundImage: `url(${bgimg})` }}>
                <div className='getintbg'></div>
                <Container>
                    <Row className='w-100'>
                        <Col className='col-md-6 desktop'>
                            <div className='content-box'>
                                <h2>Get In Touch</h2>
                                {/* <div className="h1">Get in</div>
                        <div className="h1"><span className='animate-underline'>touch</span></div> */}
                                <div className="h1"><span className='animate-underline' style={{ paddingBottom: "40px" }}> With Us </span></div>
                                <p className='get-para'>Our experts are always looking to solve new <br />challenges. Write to us and one of our experts will get <br />in touch with you. </p>
                            </div>

                        </Col>

                        <Col className='col-md-6'>
                            <div className='form-box '>
                                <Formik>
                                    {() => (
                                        <Form onSubmit={handleSubmit}>

                                            <div className='mb-3'>
                                                <label htmlFor="name" className="">Name*</label>
                                                <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="name" className="" component="div" /> */}
                                                <div className='errormsg'> {errors.name && touched.name ? (
                                                    <p className="form-error">{errors.name}</p>
                                                ) : null}</div>
                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="email" className="">Email ID*</label>
                                                <Field type="email" name="email" placeholder='' aria-labelledby="email" value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="email" component="div" /> */}
                                                <div className='errormsg'> {errors.email && touched.email ? (
                                                <p className="form-error">{errors.email}</p>
                                            ) : null}</div>
                                            </div>
                                           
                                            <div className='mb-3'>
                                                <label htmlFor="phone" className="">Phone*</label>
                                                <PhoneInput placeholder="" name="phone"  aria-labelledby="phone" value={phone}                                                         
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="IN"
                                                    onChange={setphone}
                                                     // onBlur={handleBlur} 
                                                    rules={{ required: true, validate: isPossiblePhoneNumber }}
                                                /> 

                                                <div className='errormsg'> {phoneerrmsg ? (
                                                    <p className="form-error">{phoneerrmsg}</p>
                                                ) : null}</div>
                                            </div>

                                            <div className='mb-3'>
                                                <label htmlFor="message" className="">Message*</label>
                                                <Field component="textarea" name="message" row="10" aria-labelledby="message" value={values.message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {/* <ErrorMessage name="message" component="div" /> */}
                                                <div className='errormsg'> {errors.message && touched.message ? (
                                                <p className="form-error">{errors.message}</p>
                                            ) : null}</div>
                                            </div>

                                            <div className='text-left'>
                                                {(hide) ? (
                                                        <button 
                                                        disabled
                                                        style={{opacity:"0.5"}}
                                                        type="submit"
                                                    >
                                                        Send message
                                                    </button>
                                                    
                                                    ) :  (
                                                        <button type="submit">Send message</button>                       
                                                        )
                                                    }
                                            </div>

                                            {/* <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>   */}
                                        
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='getint-section mobile' style={{ backgroundImage: `url(${bgimg})` }}>
                <div className='getintbg'></div>
                <Container>
                    <Row className='form-text'>
                        <div className='content-box mt-5'>
                            <h2>Get In Touch</h2>
                            <div className="h1">With <span className='animate-underline'> Us</span></div>
                            <p className='get-para'>Our experts are always looking to solve new challenges. Write to us and one of our experts will get in touch with you. </p>
                        </div>
                    </Row>
                </Container>
                <Container>
                    <Row className=''>
                        <div className='form-box mt-5 pt-3'>
                            <Formik>
                                {() => (
                                    <Form onSubmit={handleSubmit}>

                                        <div className='mb-3'>
                                            <label htmlFor="name" className="">Name*</label>
                                            <Field type="text" name="name" placeholder='' aria-labelledby="name" value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {/* <ErrorMessage name="name" className="" component="div" /> */}
                                            <div className='errormsg'> {errors.name && touched.name ? (
                                            <p className="form-error">{errors.name}</p>
                                        ) : null}</div>
                                        </div>
                                        

                                        <div className='mb-3'>
                                            <label htmlFor="email" className="">Email ID*</label>
                                            <Field type="email" name="email" placeholder='' aria-labelledby="email" value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {/* <ErrorMessage name="email" component="div" /> */}
                                            <div className='errormsg'> {errors.email && touched.email ? (
                                            <p className="form-error">{errors.email}</p>
                                        ) : null}</div>
                                        </div>

                                        <div className='mb-3'>
                                                <label htmlFor="phone" className="">Phone*</label>
                                                <PhoneInput placeholder="" name="phone"  aria-labelledby="phone" value={phone}                                                         
                                                    international
                                                    countryCallingCodeEditable={false}
                                                    defaultCountry="IN"
                                                    onChange={setphone}
                                                     // onBlur={handleBlur} 
                                                    rules={{ required: true, validate: isPossiblePhoneNumber }}
                                                /> 

                                                <div className='errormsg'> {phoneerrmsg ? (
                                                    <p className="form-error">{phoneerrmsg}</p>
                                                ) : null}</div>
                                            </div>

                                        <div className='mb-3'>
                                            <label htmlFor="message" className="">Message*</label>
                                            <Field component="textarea" name="message" row="10" aria-labelledby="message" value={values.message}
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {/* <ErrorMessage name="message" component="div" /> */}
                                            <div className='errormsg'> {errors.message && touched.message ? (
                                            <p className="form-error">{errors.message}</p>
                                        ) : null}</div>
                                        </div>


                                        <div className='text-center pb-5'>
                                            {(hide) ? (
                                                <button disabled style={{opacity:"0.5"}} type="submit">
                                                        Send message
                                                </button>
                                                ) :  (
                                                    <button type="submit">Send message</button>                       
                                                      )
                                            }
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>


                    </Row>
                </Container>

                    {/* success popup */}
                    <Modal
                        show={modalShow}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        >
                        <Modal.Body className='text-center p-5'>
                            {/* {resonseMsg} */}
                            <div className="form-success text-center mt-5"    dangerouslySetInnerHTML={{ __html: response }}></div>  
                            {/* <h5>Thank you for applying at Preferred Square. </h5> <p> We will be reviewing your application and our team will get in touch with you.</p> */}
                             <button className='close-btn' onClick={() => setModalShow(false)} >Ok</button>
                        </Modal.Body>
                    </Modal>


            </section >
        </>
    )
}
